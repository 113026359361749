import React, { useState, useEffect } from "react";
import {
  FaEye,
  FaEyeSlash,
  FaSpinner,
  FaWarehouse,
  FaPlus,
  FaEdit,
  FaCheck,
  FaImage,
  FaQrcode,
  FaList,
  FaCar,
  FaSearch,
  FaExternalLinkAlt,
  FaTrash,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import { BiError, BiQrScan, BiPackage } from "react-icons/bi";
import { MdQrCode2 } from "react-icons/md";
import { QRCodeCanvas } from "qrcode.react";
import { jsPDF } from "jspdf";
import { supabase } from "./supabaseClient"; // Asegúrate de tener este archivo configurado

const SignInForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Estado de autenticación persistente
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuth = localStorage.getItem("isAuthenticated");
    return savedAuth === "true";
  });

  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [editForm, setEditForm] = useState({
    model: "",
    year: "",
    color: "",
    precio: "",
    image: "",
    link: "",
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activePanel, setActivePanel] = useState("stock");
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  // Estado de vehículos desde Supabase
  const [vehicles, setVehicles] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      fetchVehicles();
      subscribeToChanges();
    }
  }, [isAuthenticated]);

  const validateCredentials = (email, password) => {
    return email === "accar@accar.com" && password === "Diosestaconaccar2024";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";
    if (name === "email") {
      if (!value) {
        error = "El correo electrónico es requerido";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Correo electrónico inválido";
      }
    } else if (name === "password") {
      if (!value) {
        error = "La contraseña es requerida";
      }
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setErrors({
        email: !formData.email ? "El correo electrónico es requerido" : "",
        password: !formData.password ? "La contraseña es requerida" : "",
      });
      return;
    }

    if (validateCredentials(formData.email, formData.password)) {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true");
    } else {
      setErrors({
        email: "Correo electrónico o contraseña incorrectos",
        password: "Correo electrónico o contraseña incorrectos",
      });
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  // Función para obtener los vehículos desde Supabase
  const fetchVehicles = async () => {
    const { data, error } = await supabase.from("VEHÍCULOS").select("*");
    if (error) {
      console.error("Error al obtener los vehículos:", error.message);
      setMessage("Error al obtener los vehículos");
    } else {
      setVehicles(data);
      setMessage("");
    }
  };

  // Función para agregar un vehículo
  const handleAddVehicle = async (e) => {
    e.preventDefault();
    if (
      !editForm.model ||
      !editForm.year ||
      !editForm.color ||
      !editForm.precio ||
      !editForm.image ||
      !editForm.link
    ) {
      setMessage("Todos los campos son obligatorios");
      return;
    }
    if (isNaN(editForm.year) || editForm.year <= 0) {
      setMessage("El año debe ser un número positivo");
      return;
    }
    const { data, error } = await supabase
      .from("VEHÍCULOS")
      .insert([editForm])
      .select();
    if (error) {
      console.error("Error al agregar el vehículo:", error.message);
      setMessage("Error al agregar el vehículo");
    } else {
      setEditForm({
        model: "",
        year: "",
        color: "",
        precio: "",
        image: "",
        link: "",
      });
      setShowAddVehicleModal(false);
      setMessage("Vehículo agregado con éxito");
    }
  };

  // Función para actualizar un vehículo
  const handleUpdateVehicle = async () => {
    const { data, error } = await supabase
      .from("VEHÍCULOS")
      .update(editForm)
      .eq("id", editingId)
      .select();

    if (error) {
      console.error("Error al actualizar el vehículo:", error.message);
      setMessage("Error al actualizar el vehículo");
    } else {
      setEditingId(null);
      setEditForm({
        model: "",
        year: "",
        color: "",
        precio: "",
        image: "",
        link: "",
      });
      setMessage("Vehículo actualizado con éxito");
    }
  };

  // Función para eliminar un vehículo
  const handleDeleteVehicle = async (id) => {
    const { error } = await supabase.from("VEHÍCULOS").delete().eq("id", id);
    if (error) {
      console.error("Error al eliminar el vehículo:", error.message);
      setMessage("Error al eliminar el vehículo");
    } else {
      setMessage("Vehículo eliminado con éxito");
    }
  };

  // Suscripción a cambios en tiempo real
  const subscribeToChanges = () => {
    const channel = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "VEHÍCULOS" },
        (payload) => {
          console.log("¡Cambio recibido!", payload);
          fetchVehicles(); // Refresca la lista de vehículos después de cada cambio
        }
      )
      .subscribe();
  };

  const handleEditVehicle = (vehicle) => {
    setEditingId(vehicle.id);
    setEditForm(vehicle);
  };

  const handleVehicleClick = (link) => {
    window.open(link, "_blank");
  };

  const filteredVehicles = searchTerm
    ? vehicles.filter((vehicle) =>
        Object.values(vehicle)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : vehicles;

  const QRGenerator = () => {
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [showQR, setShowQR] = useState(false);

    const validateURL = (input) => {
      try {
        new URL(input);
        return true;
      } catch (err) {
        return false;
      }
    };

    const handleGenerate = () => {
      if (!url) {
        setError("Por favor, ingresa una URL");
        setShowQR(false);
        return;
      }

      if (!validateURL(url)) {
        setError("Por favor, ingresa una URL válida");
        setShowQR(false);
        return;
      }

      setError("");
      setIsGenerating(true);

      setTimeout(() => {
        setIsGenerating(false);
        setShowQR(true);
      }, 1000);
    };

    const handleDownload = () => {
      const canvas = document.getElementById("qr-code-canvas");
      if (canvas) {
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = 100;
        const imgHeight = 100;
        const x = (pdfWidth - imgWidth) / 2;
        const y = (pdfHeight - imgHeight) / 2;

        pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        pdf.text("Código QR para: " + url, 10, 20);
        pdf.save("codigo_qr.pdf");
      }
    };

    const handleOpenLink = () => {
      if (validateURL(url)) {
        window.open(url, "_blank");
      }
    };

    return (
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="flex-1 space-y-6">
          <div className="relative">
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Ingresa la URL aquí"
              aria-label="Entrada de URL"
              className="w-full px-4 py-3 border-2 border-gray-600 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-200 outline-none bg-gray-700 text-white placeholder-gray-400"
            />
            {error && (
              <div className="absolute -bottom-6 left-0 flex items-center text-red-400 text-sm">
                <BiError className="mr-1" />
                {error}
              </div>
            )}
          </div>

          <button
            onClick={handleGenerate}
            disabled={isGenerating}
            aria-label="Generar Código QR"
            className="w-full bg-red-600 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded-lg transition-all duration-200 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
          >
            {isGenerating ? "Generando..." : "Generar Código QR"}
          </button>
        </div>

        {showQR && (
          <div className="flex-1 flex flex-col items-center space-y-6 animate-fade-in">
            <div
              className="p-4 bg-gray-700 rounded-xl shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-200"
              onClick={handleOpenLink}
              role="button"
              aria-label="Clic para abrir URL"
            >
              <QRCodeCanvas
                id="qr-code-canvas"
                value={url}
                size={200}
                bgColor="#374151"
                fgColor="#FFFFFF"
                level="H"
                includeMargin={true}
              />
            </div>

            <div className="flex space-x-4">
              <button
                onClick={handleDownload}
                className="flex items-center space-x-2 bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-200"
                aria-label="Descargar Código QR como PDF"
              >
                <FiDownload />
                <span>Descargar PDF</span>
              </button>

              <button
                onClick={handleOpenLink}
                className="flex items-center space-x-2 bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-200"
                aria-label="Abrir URL"
              >
                <FiExternalLink />
                <span>Abrir Enlace</span>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const AddVehicleModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-xl p-6 w-full max-w-md">
        <h3 className="text-xl font-bold text-white mb-4">
          Agregar Nuevo Vehículo
        </h3>
        <form onSubmit={handleAddVehicle} className="space-y-4">
          <input
            type="text"
            placeholder="Modelo"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.model}
            onChange={(e) => setEditForm({ ...editForm, model: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Año"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.year}
            onChange={(e) => setEditForm({ ...editForm, year: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Color"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.color}
            onChange={(e) => setEditForm({ ...editForm, color: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Precio"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.precio}
            onChange={(e) => setEditForm({ ...editForm, precio: e.target.value })}
            required
          />
          <input
            type="url"
            placeholder="URL de la imagen"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.image}
            onChange={(e) => setEditForm({ ...editForm, image: e.target.value })}
            required
          />
          <input
            type="url"
            placeholder="Link de coches.net"
            className="w-full p-2 rounded bg-gray-700 text-white"
            value={editForm.link}
            onChange={(e) => setEditForm({ ...editForm, link: e.target.value })}
            required
          />
          {message && <p className="text-center text-red-400">{message}</p>}
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowAddVehicleModal(false)}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Agregar
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const StockManagementPanel = () => (
    <div className="flex h-screen bg-gray-900">
      {/* Menú lateral */}
      <div
        className={`fixed inset-y-0 left-0 transform ${
          showSidebar ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0 transition-transform duration-200 ease-in-out w-64 bg-gray-800 p-6 space-y-6 z-40`}
      >
        {/* Botón para cerrar el menú en móviles */}
        <div className="md:hidden flex justify-end">
          <button
            className="text-white mb-4"
            onClick={() => setShowSidebar(false)}
          >
            <FaTimes size={24} />
          </button>
        </div>

        <div className="space-y-4">
          <button
            onClick={() => {
              setActivePanel("stock");
              setShowSidebar(false);
            }}
            className={`w-full flex items-center gap-3 p-3 rounded-lg ${
              activePanel === "stock" ? "bg-red-500" : "bg-gray-700"
            } text-white transition-colors`}
          >
            <FaList /> Ver Stock
          </button>
          <button
            onClick={() => {
              setActivePanel("scanQr");
              setShowSidebar(false);
            }}
            className={`w-full flex items-center gap-3 p-3 rounded-lg ${
              activePanel === "scanQr" ? "bg-red-500" : "bg-gray-700"
            } text-white transition-colors`}
          >
            <BiQrScan /> Escanear QR
          </button>
          <button
            onClick={() => {
              setActivePanel("generateQr");
              setShowSidebar(false);
            }}
            className={`w-full flex items-center gap-3 p-3 rounded-lg ${
              activePanel === "generateQr" ? "bg-red-500" : "bg-gray-700"
            } text-white transition-colors`}
          >
            <MdQrCode2 /> Generar QR
          </button>
        </div>
        <div className="pt-6 border-t border-gray-700">
          <button
            onClick={() => {
              setSelectedLocation(null);
              setShowSidebar(false);
            }}
            className="w-full p-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Volver a Ubicaciones
          </button>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="flex-1 flex flex-col">
        {/* Header para móviles */}
        <div className="md:hidden flex items-center justify-between p-4 bg-gray-800">
          {/* Botón de menú */}
          <button className="text-white" onClick={() => setShowSidebar(true)}>
            <FaBars size={24} />
          </button>
          {/* Barra de búsqueda */}
          <div className="flex-1 mx-4">
            <div className="relative">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Buscar..."
                className="w-full pl-10 pr-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          {/* Botón de agregar vehículo */}
          <button
            onClick={() => setShowAddVehicleModal(true)}
            className="px-2 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 flex items-center"
          >
            <FaPlus />
          </button>
        </div>

        {/* Contenido */}
        <div className="flex-1 p-8 overflow-auto">
          {/* Búsqueda y botón de agregar (escritorio) */}
          <div className="hidden md:flex justify-between items-center mb-6">
            <div className="flex-1 max-w-md">
              <div className="relative">
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Buscar por modelo, color, precio o año..."
                  className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={() => setShowAddVehicleModal(true)}
              className="ml-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 flex items-center gap-2"
            >
              <FaPlus /> Agregar Vehículo
            </button>
          </div>

          {activePanel === "stock" && (
            <>
              {message && (
                <p className="text-center text-red-400 mb-4">{message}</p>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredVehicles.map((vehicle) => (
                  <div
                    key={vehicle.id}
                    className="bg-gray-800 rounded-xl p-6 shadow-lg cursor-pointer transform transition-all duration-300 hover:scale-105"
                    onClick={() => handleVehicleClick(vehicle.link)}
                  >
                    <div className="h-48 rounded-lg overflow-hidden mb-4">
                      <img
                        src={vehicle.image}
                        alt={vehicle.model}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    {editingId === vehicle.id ? (
                      <div className="space-y-4">
                        <input
                          type="text"
                          value={editForm.model}
                          onChange={(e) =>
                            setEditForm({ ...editForm, model: e.target.value })
                          }
                          className="w-full p-2 rounded bg-gray-700 text-white"
                        />
                        <input
                          type="number"
                          value={editForm.year}
                          onChange={(e) =>
                            setEditForm({ ...editForm, year: e.target.value })
                          }
                          className="w-full p-2 rounded bg-gray-700 text-white"
                        />
                        <input
                          type="text"
                          value={editForm.color}
                          onChange={(e) =>
                            setEditForm({ ...editForm, color: e.target.value })
                          }
                          className="w-full p-2 rounded bg-gray-700 text-white"
                        />
                        <input
                          type="text"
                          value={editForm.precio}
                          onChange={(e) =>
                            setEditForm({ ...editForm, precio: e.target.value })
                          }
                          className="w-full p-2 rounded bg-gray-700 text-white"
                        />
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingId(null);
                            }}
                            className="p-2 text-gray-400 hover:text-white"
                          >
                            Cancelar
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdateVehicle();
                            }}
                            className="p-2 text-green-500 hover:text-green-400"
                          >
                            <FaCheck />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3 className="text-xl font-bold text-white mb-2">
                          {vehicle.model}
                        </h3>
                        <div className="space-y-2 text-gray-300">
                          <p>Año: {vehicle.year}</p>
                          <p>Color: {vehicle.color}</p>
                          <p>Precio: {vehicle.precio}</p>
                        </div>
                        <div className="flex justify-between mt-4">
                          <div className="space-x-2">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditVehicle(vehicle);
                              }}
                              className="p-2 text-blue-500 hover:text-blue-400"
                            >
                              <FaEdit />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteVehicle(vehicle.id);
                              }}
                              className="p-2 text-red-500 hover:text-red-400"
                            >
                              <FaTrash />
                            </button>
                          </div>
                          <FaExternalLinkAlt className="text-gray-400 hover:text-white" />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {activePanel === "generateQr" && (
            <div className="max-w-4xl mx-auto bg-gray-800 rounded-2xl shadow-xl p-6 sm:p-10 border border-gray-700">
              <h1 className="text-3xl font-bold text-white mb-8 text-center">
                Generador de Código QR
              </h1>
              <QRGenerator />
            </div>
          )}
          {activePanel === "scanQr" && (
            <div className="text-center text-white p-8">
              <BiQrScan className="text-6xl mx-auto mb-4" />
              <h2 className="text-2xl font-bold mb-4">Escáner de QR</h2>
              <p>La funcionalidad de escaneo se implementará aquí</p>
            </div>
          )}
        </div>
      </div>
      {showAddVehicleModal && <AddVehicleModal />}
    </div>
  );

  const [locations] = useState([
    {
      id: 1,
      name: "ACCAR Las Rozas",
      description: "Gestionar el inventario de la sucursal Las Rozas",
      address: "C. Dublín, 7, 28232 Las Rozas de Madrid, Madrid",
      image:
        "https://lh5.googleusercontent.com/p/AF1QipMVaNE4J-aj-FggF2JAk_-VCK-Ykdnc6DbBOwl5=w400-h300-k-no",
    },
    {
      id: 2,
      name: "ACCAR Alcalá",
      description: "Gestionar el inventario de la sucursal Alcalá",
      address: "5, C. Francisco Alonso, 28806 Alcalá de Henares, Madrid",
      image:
        "https://lh3.googleusercontent.com/p/AF1QipPL4p6kJ2PXuaWKgz6EqBTUCnaBvvWqmkoUrEXv=s1360-w1360-h1020",
    },
    {
      id: 3,
      name: "ACCAR Barcelona",
      description: "Gestionar el inventario de la sucursal Barcelona",
      address: "Carrer Can Magre, 1, 08100 Mollet del Vallès, Barcelona",
      image:
        "https://lh3.googleusercontent.com/p/AF1QipPjKasLwwca5nSou1Zzgms2FlA6IPfWvbG25WNh=s1360-w1360-h1020",
    },
  ]);

  if (isAuthenticated && selectedLocation) {
    return <StockManagementPanel />;
  }

  if (isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-900 p-6">
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-3xl font-bold text-white">
            ACCAR - Gestión de Stock
          </h1>
        </div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {locations.map((location) => (
            <div
              key={location.id}
              className="bg-gray-800 rounded-2xl shadow-xl overflow-hidden hover:shadow-2xl transition-shadow duration-300 border border-gray-700 flex flex-col"
            >
              <div className="h-48 relative">
                <div
                  className="h-full bg-cover bg-center"
                  style={{ backgroundImage: `url("${location.image}")` }}
                ></div>
              </div>
              <div className="p-6 flex-grow flex flex-col">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-2xl font-bold text-white">
                    {location.name}
                  </h2>
                  <FaWarehouse className="text-3xl text-red-500" />
                </div>
                <div className="flex-grow">
                  <p className="text-gray-400 mb-3">{location.description}</p>
                  <p className="text-gray-400">{location.address}</p>
                </div>
                <button
                  onClick={() => handleLocationSelect(location)}
                  className="w-full py-3 px-4 bg-gradient-to-r from-red-500 to-red-700 text-white rounded-xl hover:from-red-600 hover:to-red-800 transition-all duration-300 font-medium mt-6"
                >
                  Acceder al Stock
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-900 bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1544636331-e26879cd4d9b?ixlib=rb-4.0.3')",
      }}
    >
      <div className="relative w-full max-w-md mx-4 px-6 pt-10 pb-8 bg-gray-800/90 shadow-2xl backdrop-blur-lg rounded-3xl space-y-8 border border-gray-700">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-800/50 to-black/50 rounded-3xl backdrop-blur-xl filter" />
        <div className="relative">
          <h2 className="text-center text-4xl font-bold tracking-tight text-white mb-8 drop-shadow-lg">
            ACCAR - Iniciar Sesión
          </h2>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md space-y-4">
              <div className="relative">
                <label htmlFor="email" className="sr-only">
                  Correo electrónico
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`block w-full px-4 py-3 rounded-2xl bg-gray-700/50 backdrop-blur-sm border ${
                    errors.email ? "border-red-500" : "border-gray-600"
                  } text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-300`}
                  placeholder="Correo electrónico"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <p className="mt-2 text-sm text-red-400 font-medium">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="relative flex items-center">
                <label htmlFor="password" className="sr-only">
                  Contraseña
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className={`block w-full px-4 py-3 pr-12 rounded-2xl bg-gray-700/50 backdrop-blur-sm border ${
                    errors.password ? "border-red-500" : "border-gray-600"
                  } text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-300`}
                  placeholder="Contraseña"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  className="absolute right-3 text-gray-400 hover:text-white focus:outline-none transition-colors duration-200"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEyeSlash className="w-5 h-5" />
                  ) : (
                    <FaEye className="w-5 h-5" />
                  )}
                </button>
                {errors.password && (
                  <p className="mt-2 text-sm text-red-400 font-medium">
                    {errors.password}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={
                  isLoading ||
                  Object.values(errors).some((error) => error) ||
                  !formData.email ||
                  !formData.password
                }
                className="w-full py-3 px-4 rounded-2xl text-white bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 font-medium transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:from-red-500 disabled:hover:to-red-700 shadow-lg hover:shadow-xl"
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin h-5 w-5 mx-auto" />
                ) : (
                  "Iniciar Sesión"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
